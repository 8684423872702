exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aboutus-tsx": () => import("./../../../src/pages/aboutus.tsx" /* webpackChunkName: "component---src-pages-aboutus-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-dashboard-admin-tsx": () => import("./../../../src/pages/dashboard/admin.tsx" /* webpackChunkName: "component---src-pages-dashboard-admin-tsx" */),
  "component---src-pages-dashboard-criar-nft-tsx": () => import("./../../../src/pages/dashboard/criarNft.tsx" /* webpackChunkName: "component---src-pages-dashboard-criar-nft-tsx" */),
  "component---src-pages-dashboard-domain-tsx": () => import("./../../../src/pages/dashboard/domain.tsx" /* webpackChunkName: "component---src-pages-dashboard-domain-tsx" */),
  "component---src-pages-dashboard-donationcharts-tsx": () => import("./../../../src/pages/dashboard/donationcharts.tsx" /* webpackChunkName: "component---src-pages-dashboard-donationcharts-tsx" */),
  "component---src-pages-dashboard-index-tsx": () => import("./../../../src/pages/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-index-tsx" */),
  "component---src-pages-dashboard-login-tsx": () => import("./../../../src/pages/dashboard/login.tsx" /* webpackChunkName: "component---src-pages-dashboard-login-tsx" */),
  "component---src-pages-dashboard-organization-member-tsx": () => import("./../../../src/pages/dashboard/organization-member.tsx" /* webpackChunkName: "component---src-pages-dashboard-organization-member-tsx" */),
  "component---src-pages-dashboard-organization-tsx": () => import("./../../../src/pages/dashboard/organization.tsx" /* webpackChunkName: "component---src-pages-dashboard-organization-tsx" */),
  "component---src-pages-dashboard-project-detail-tsx": () => import("./../../../src/pages/dashboard/project-detail.tsx" /* webpackChunkName: "component---src-pages-dashboard-project-detail-tsx" */),
  "component---src-pages-dashboard-projects-tsx": () => import("./../../../src/pages/dashboard/projects.tsx" /* webpackChunkName: "component---src-pages-dashboard-projects-tsx" */),
  "component---src-pages-dashboard-sorteio-tsx": () => import("./../../../src/pages/dashboard/sorteio.tsx" /* webpackChunkName: "component---src-pages-dashboard-sorteio-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-privacy-policy-tsx": () => import("./../../../src/pages/legal/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-legal-privacy-policy-tsx" */),
  "component---src-pages-legal-refund-tsx": () => import("./../../../src/pages/legal/refund.tsx" /* webpackChunkName: "component---src-pages-legal-refund-tsx" */),
  "component---src-pages-legal-terms-tsx": () => import("./../../../src/pages/legal/terms.tsx" /* webpackChunkName: "component---src-pages-legal-terms-tsx" */),
  "component---src-pages-noreward-tsx": () => import("./../../../src/pages/noreward.tsx" /* webpackChunkName: "component---src-pages-noreward-tsx" */),
  "component---src-pages-project-tsx": () => import("./../../../src/pages/project.tsx" /* webpackChunkName: "component---src-pages-project-tsx" */),
  "component---src-pages-reward-tsx": () => import("./../../../src/pages/reward.tsx" /* webpackChunkName: "component---src-pages-reward-tsx" */),
  "component---src-pages-userguide-tsx": () => import("./../../../src/pages/userguide.tsx" /* webpackChunkName: "component---src-pages-userguide-tsx" */)
}

